import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { siteMetadata } from "../../gatsby-config";
import Layout from "../components/layout";
import { SectionAnimated, DivAnimated } from "../components/AnimatedSection";
import {
  hero,
  design,
  design__media,
  img_caption,
  intro,
  overviewCaseStudy,
  overviewCaseStudy__content,
  overviewCaseStudy__title,
  overviewList,
  subtitle,
  title,
} from "../components/project.module.scss";

const ProjectCuPage = () => {
  const imgVariants = {
    animate: { opacity: 1, scale: 1, y: 0 },
    initial: {
      opacity: 0,
      scale: 0.95,
      y: 0,
    },
  };
  return (
    <>
      <Helmet>
        <body />
        <title>CU</title>
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <div className="container-fluid" style={{ padding: 0 }}>
          <div style={{ background: "#C7E7FF" }}>
            <div className="row">
              <DivAnimated cn={title}>
                <h1 style={{ color: "#000000" }}>
                  Women Coding Community
                </h1>
              </DivAnimated>
              <div className="col-12">
                <DivAnimated cn={intro}>
                  <h2 style={{ color: "#000000" }} className={subtitle}>
                    New Platform Design
                  </h2>
                </DivAnimated>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <motion.div
                  variants={imgVariants}
                  initial="initial"
                  animate="animate"
                  transition={{ duration: 0.8, ease: "easeIn" }}
                >
                  <StaticImage
                    src="../images/teaser-wcc.webp"
                    quality="100"
                    loading="eager"
                    placeholder="blurred"
                    layout="fullWidth"
                    className={hero}
                    alt="Desktop and mobile designs of department websites"
                  />
                </motion.div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ color: "#000000" }}>
                <section className={overviewList}>
                  <div className="col-md-6">
                    <h3>Project</h3>
                    <p>
                      Website redesign for womencodingcommunity.com,  an international non-profit dedicated to empowering diverse women in technology careers.
                    </p>
                    <h3>Role</h3>
                    <p>UI/UX Design Lead &amp; Frontend Designer</p>
                  </div>
                  <div className="col-md-6">

                  <h3>Responsibilities</h3>
                    <p>
                      UI/UX Design, Prototyping, Design Guidelines Documentation, QA Testing, Frontend Component Styling
                    </p>
                    <h3>Tools</h3>
                    <p>
                      Figma, Google Material Design System, MUI, Miro, HTML/CSS, Photoshop, React
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SectionAnimated cn={overviewCaseStudy}>
                <div className={overviewCaseStudy__title}>
                  <h2>Rebranding and Platform Initiative</h2>
                </div>
                <div className={overviewCaseStudy__content}>
                  <h3>Background and Challenge</h3>
                  <p>
                  In the aftermath of the dissolution of one of the largest networks for women in tech in 2024, the dedicated women of the London community decided to continue their mission, leading to the creation of the Women Coding Community. Despite being given only a few weeks' notice of the dissolution, we quickly mobilized to keep our community alive. As a former member, I embraced the opportunity to contribute by taking on the role of UI/UX Lead during the formation of this new non-profit organization in the spring of 2024.
                  </p>
                  <h3>Design and Collaboration</h3>
                  <p>
                  Drawing on my experience as a past volunteer for the London community, I spearheaded the UI rebranding efforts for the Women Coding Community. This involved crafting all new UI page designs and templates for the new website, leveraging Google’s Material UI design system framework. As the sole UI/UX designer, I collaborated closely with the lead graphic designer who developed our new brand color palette and logo.
                  </p>
                  <h3>MVP Implementation and Launch</h3>
                  <p>
                  Given our tight timeline, I led the UI implementation of our rebranding initiative to update the old London community website, hosted on GitHub Pages, within just a couple of weeks. This effort saw volunteers from our community come together to code and bring the redesigned site to life as an open-source project. The purpose of the website is to foster a diverse, vibrant, and inclusive community, welcoming women of all backgrounds, experiences, and skill levels. It serves as a hub for mentorship, networking, and support, connecting like-minded women who share a passion for technology.
                  </p>
                  <div>
                    <StaticImage
                      src="../images/wcc-old-site.webp"
                      quality="100"
                      layout="fullWidth"
                      placeholder="blurred"
                      alt=""
                    />
                    <p className={img_caption}>
                      Page examples of the London community website before
                      its redesign.
                    </p>
                  </div>
                </div>
              </SectionAnimated>
            </div>
          </div>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/wcc-desktop-1.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#1d4f91" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/wcc-mobile-1.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Mobile designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/wcc-desktop-2.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated
            cn={design}
            style={{ background: "#1d4f91" }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.8,
                duration: 1,
              },
            }}
          >
            <div className={design__media}>
              <StaticImage
                src="../images/wcc-mobile-2.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Mobile designs of department websites"
              />
            </div>
          </DivAnimated>
          <DivAnimated cn={design} style={{ background: "#c6d1de" }}>
            <div className={design__media}>
              <StaticImage
                src="../images/wcc-desktop-3.webp"
                quality="100"
                layout="fullWidth"
                placeholder="blurred"
                alt="Desktop designs of department websites"
              />
            </div>
          </DivAnimated>
        </div>
      </Layout>
    </>
  );
};

export default ProjectCuPage;
